import React from "react";
import App from "../components/App";
import Hero from "../components/Hero";
import colors from "../styles/colors";

const PageNotFound = () => {
  return (
    <App>
      <Hero
        heading="Oops..."
        description="Try navigating the site to find what you are looking for"
        paragraphColor={colors.white}
        contactUs
      ></Hero>
    </App>
  );
};

export default PageNotFound;
